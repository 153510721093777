import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  FV_KEY_IS_SEALED,
} from '../../../constants/filters';
import { 
  PL_PERMALINK_LORCANA,
  PL_PERMALINK_MAGIC, 
  PL_PERMALINK_POKEMON, 
  PL_PERMALINK_STARWARS, 
} from '../../../constants/product';
import {
  STORE_CODE,
  STORE_CODE_OASISCHILE,
} from '../../../constants/store';
import * as tx from '../../../constants/strings';
import { URL_BUY_PRODUCT_LINE } from '../../../constants/urls';

import { stringFormat } from '../../../utils/formatting';
import { getOrderedMenu } from '../../../utils/product';

import '../style/_headersubmenu.scss';

import * as headerActionCreators from '../../../actions/header';
let allActionCreators = Object.assign({}, headerActionCreators);

export class HeaderSubmenuBuy extends Component {

  getMenuLinks() {
    return getOrderedMenu(this.props.productLine.menus);
  }

  toggleMenu() {
    if(this.props.toggleFullMenu) {
      this.props.headerToggleUserMenu();
    }

    if(this.props.closeMenu) {
      this.props.closeMenu();
    }
  }

  render() {

  	const {t} = this.props;
    const links = this.getMenuLinks();
  	
    return <div className={'HeaderSubmenuBuy HeaderSubmenuCore'}>
      <div className='humSection'>
        <div className='humSectionTitle' style={this.props.hideTitle ? { display: 'none' } : {}}>{t(tx.TX_BUY)}</div>
        <div className='humSectionBody'>
          {links.length === 0 ?
            <div className='noLinksItem'>{t(tx.TX_MENU_NO_ITEMS)}</div> :
            <>
              {links.map((link, i) => {

                const detailPermalinks = [
                  PL_PERMALINK_MAGIC,
                  PL_PERMALINK_POKEMON,
                  PL_PERMALINK_LORCANA,
                  PL_PERMALINK_STARWARS,
                ];
                
                if(detailPermalinks.includes(link.permalink)) {
                  return <div key={i}>
                    <NavLink 
                      to={stringFormat(URL_BUY_PRODUCT_LINE, { productLine: link.permalink })} 
                      className={`humSectionLink withSublinks`} 
                      onClick={this.toggleMenu.bind(this)}>
                        {link.name}
                    </NavLink>

                    <Link 
                      to={{
                        pathname: stringFormat(URL_BUY_PRODUCT_LINE, { productLine: link.permalink }),
                        state: { filters: {
                          [FV_KEY_IS_SEALED]: [ 'false' ],
                        }}
                      }}
                      className='humSectionSublink' 
                      onClick={this.toggleMenu.bind(this)}>
                        {t(tx.TX_MAGIC_MENU_SINGLES)}
                    </Link>

                    <Link 
                      to={{
                        pathname: stringFormat(URL_BUY_PRODUCT_LINE, { productLine: link.permalink }),
                        state: { filters: {
                          [FV_KEY_IS_SEALED]: [ 'true' ],
                        }}
                      }}
                      className='humSectionSublink' 
                      onClick={this.toggleMenu.bind(this)}>
                        {t(tx.TX_MAGIC_MENU_SEALED)}
                    </Link>

                    {STORE_CODE === STORE_CODE_OASISCHILE && link.permalink === PL_PERMALINK_MAGIC ?
                      <Link 
                        to={stringFormat(URL_BUY_PRODUCT_LINE, { productLine: link.permalink })} 
                        className='humSectionSublink' 
                        onClick={this.toggleMenu.bind(this)}>
                          {t(tx.TX_MAGIC_MENU_SECRET_LAIR)}
                      </Link> :
                      null
                    }
                  </div>;
                }

                return <NavLink 
                        key={i}
                        to={stringFormat(URL_BUY_PRODUCT_LINE, { productLine: link.permalink })} 
                        className='humSectionLink' 
                        onClick={this.toggleMenu.bind(this)}>
                          {link.name}
                       </NavLink>;
              })}
            </>
          }
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    productLine: state.productLine,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(HeaderSubmenuBuy));


